import { Component, inject } from "@angular/core";
import { CommonModule } from "@angular/common";
import { DateFnsModule } from "ngx-date-fns";
import { QuillModule } from "ngx-quill";
import { EmptyParagraphPipe } from "@app/helpers/html/pipes/empty-paragraph";
import { NotificationsApiService } from "@app/api/notifications";
import { NzCardModule } from "ng-zorro-antd/card";
import { ActivatedRoute, RouterModule } from "@angular/router";
import { NzEmptyModule } from "ng-zorro-antd/empty";
import { TrackByIdFn } from "@app/common/functions";
import { QueryListCountPipe } from "../../standalone/query-list-count";
import { TranslateModule } from "@ngx-translate/core";
import { toSignal } from "@angular/core/rxjs-interop";
import { map, switchMap } from "rxjs";
import { NzSpinModule } from "ng-zorro-antd/spin";
import { NzButtonModule } from "ng-zorro-antd/button";
import { NzIconModule } from "ng-zorro-antd/icon";

@Component({
  selector: "notifications-list",
  standalone: true,
  imports: [
    CommonModule,
    NzEmptyModule,
    RouterModule,
    DateFnsModule,
    QuillModule,
    EmptyParagraphPipe,
    NzCardModule,
    TranslateModule,
    QueryListCountPipe,
    NzSpinModule,
    NzButtonModule,
    NzIconModule,
  ],
  template: `
    <nz-spin [nzTip]="'Loading...'" [nzSpinning]="list()?.isFetching">
      <div class="mx-3 mt-5 mb-10">
        <div class="w-full mx-auto sm:max-w-md">
          <div class="flex flex-col space-y-5">
            <ng-container *ngIf="list()?.data?.pages as pages">
              <ng-container *ngFor="let page of pages; trackBy: trackByIdx">
                <ng-container *ngFor="let article of page.articles">
                  <div
                    class="group hover:cursor-pointer divide-y divide-dashed  divide-blue-200"
                  >
                    <div class="group p-2 rounded-md">
                      <div class="font-thin text-xs mb-2">
                        {{
                          article.publishedAt | dfnsParseIso | dfnsFormat : "PP"
                        }}
                      </div>
                      <div class="font-bold mb-2 text-primary uppercase">
                        {{ article.title }}
                      </div>
                      <quill-view
                        [content]="article.content | emptyParagraph"
                      ></quill-view>
                    </div>
                  </div>
                </ng-container>
              </ng-container>

              <div *ngIf="list()?.hasNextPage" class="flex justify-center">
                <button nz-button nzDanger (click)="loadMore()">
                  <span nz-icon nzType="plus-circle" nzTheme="outline"></span>
                  {{ "blog.button.load_more" | translate }}
                </button>
              </div>
            </ng-container>

            <ng-container
              *ngIf="list()?.data | queryListCount : 'articles' as count"
            >
              <ng-container *ngIf="!count.total">
                <nz-empty
                  [nzNotFoundContent]="'blog.result_empty_list' | translate"
                ></nz-empty>
              </ng-container>
            </ng-container>
          </div>
        </div>
      </div>
    </nz-spin>
  `,
})
export class NotificationsListComponent {
  private articleService = inject(NotificationsApiService);
  total = this.articleService.total;
  list = this.articleService.list;
  // list = toSignal(
  //   inject(ActivatedRoute).queryParams.pipe(
  //     map((queryParams) => queryParams),
  //     switchMap((searchQuery) => {
  //       console.log("called");
  //       return this.articleService.searchQuery.next({
  //         ...{ limit: 10 },
  //         ...searchQuery,
  //       });
  //       return this.articleService.getList({
  //         ...{ limit: 10 },
  //         ...searchQuery,
  //       }).result$;
  //     })
  //   )
  // );

  constructor() {
    this.articleService.searchQuery.next({ limit: 10 });
  }

  trackByIdx = TrackByIdFn;

  loadMore() {
    if (this.list()?.hasNextPage) {
      this.list()?.fetchNextPage();
    }
  }
}
