import { Injectable, Signal, inject, signal } from "@angular/core";
import { Subject } from "rxjs";
import { HttpHelperService } from "@app/helpers/http";
import { IHttpResponseError } from "@app/helpers/http/interfaces";
import { environment } from "@environments/environment";

import { map, switchMap, tap } from "rxjs/operators";
import { TranslateService } from "@ngx-translate/core";
import { NzModalService } from "ng-zorro-antd/modal";
import {
  injectQuery,
  injectInfiniteQuery,
  InfiniteQueryObserverResult,
  InfiniteData,
  toPromise,
  QueryObserverResult,
} from "@ngneat/query";
import { Result } from "@ngneat/query/lib/types";
import { toSignal } from "@angular/core/rxjs-interop";
import * as qs from "qs";
import { INotificationArticle } from "./interfaces";

import { QueryListCountPipe } from "@app/components/standalone/query-list-count";
@Injectable({
  providedIn: "root",
})
export class NotificationsApiService {
  #coreEndPoint = environment.coreEndPoint;
  #query = injectQuery();
  #queryInfinite = injectInfiniteQuery();
  list: Signal<
    InfiniteQueryObserverResult<InfiniteData<any, unknown>, Error> | undefined
  >;
  total = signal(0);
  article: Signal<QueryObserverResult<INotificationArticle, Error> | undefined>;
  searchQuery = new Subject<{
    parent?: number;
    deep?: number;
    keywords?: string;
    limit?: number;
  }>();
  slug = new Subject<string>();
  #http = inject(HttpHelperService);
  isOpen = signal(false);
  constructor(
    private translate: TranslateService,
    private modalService: NzModalService,
    private queryListCount: QueryListCountPipe
  ) {
    this.article = toSignal(
      this.slug.pipe(
        switchMap((slug) => {
          return this.getBySlug(slug).result$;
        })
      )
    );
    this.list = toSignal(
      this.searchQuery
        .pipe(
          switchMap((queryParams) => {
            return this.getList(queryParams).result$;
          })
        )
        .pipe(
          tap((res) => {
            const { isFetching, data } = res;
            if (!isFetching) {
              const total = this.queryListCount.transform(data)?.total;
              this.total.set(total);
            }
          })
        )
    );
  }

  getList(searchQuery = {}) {
    return this.#queryInfinite({
      queryKey: ["notificationArticles", searchQuery],

      queryFn: ({ pageParam, signal }) => {
        const queries = qs.stringify({
          ...searchQuery,
          ...{ skip: pageParam },
        });
        const path = `${
          this.#coreEndPoint
        }/api/notifications/articles?${queries}`;
        return toPromise({
          source: this.#http.get(path).pipe(
            tap((res) => {
              const { data } = res;
              const count = data?.count ?? 0;
              this.total.set(count);
            }),
            map((res) => res.data)
          ),
          signal,
        });
      },
      initialPageParam: 0,
      getPreviousPageParam: (firstPage) => null,
      getNextPageParam: (lastPage) => {
        if (!lastPage) return null;
        const { count, skip, limit } = lastPage;
        if (count <= skip + limit) return null;
        return lastPage.skip + lastPage.limit;
      },
    });
  }

  getBySlug(
    slug: string
  ): Result<QueryObserverResult<INotificationArticle, Error>> {
    return this.#query({
      queryKey: ["notificationArticle"] as const,
      retry: false,
      queryFn: () => {
        const path = `${
          this.#coreEndPoint
        }/api/notifications/article/slug/${slug}`;
        return this.#http.get(path).pipe(map((res) => res.data));
      },
    });
  }

  errorMessage(error: IHttpResponseError): void {
    const { name, code } = error;
    const problems = error.problems ?? [];
    const title = this.translate.instant("message.error." + name + "." + code);
    const message = problems.join("<br />");
    this.modalService.warning({
      nzTitle: title,
      nzContent: `<p class="font-bold">${message}</p>`,
      nzCentered: true,
      nzClosable: false,
    });
  }
}
